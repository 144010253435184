import AttachMoneyIcon from '@mui/icons-material/AttachMoney'
import DashboardIcon from '@mui/icons-material/Dashboard'
import DescriptionIcon from '@mui/icons-material/Description'
import EditLocationAltIcon from '@mui/icons-material/EditLocationAlt'
import FindInPageIcon from '@mui/icons-material/FindInPage'
import HistoryEduIcon from '@mui/icons-material/HistoryEdu'
import MonetizationOnIcon from '@mui/icons-material/MonetizationOn'
import PaymentIcon from '@mui/icons-material/Payment'
import PersonIcon from '@mui/icons-material/Person'
import RunningWithErrorsIcon from '@mui/icons-material/RunningWithErrors'
import SecurityIcon from '@mui/icons-material/Security'
import SettingsApplicationsIcon from '@mui/icons-material/SettingsApplications'
import StoreIcon from '@mui/icons-material/Store'
import StorefrontIcon from '@mui/icons-material/Storefront'
import Box from '@mui/material/Box'
import Divider from '@mui/material/Divider'
import Drawer from '@mui/material/Drawer'
import Toolbar from '@mui/material/Toolbar'
import useMediaQuery from '@mui/material/useMediaQuery'
import React from 'react'
import { useTranslation } from 'react-i18next'
import { useLocation, useNavigate } from 'react-router-dom'

import { isInProduction } from '../../config'
import paths from '../../utils/paths'
import {
  goToBasicCostPage,
  goToAccounts,
  goToHomePage,
  goToPropertyClassificationPage,
  goToAppraiserRegionsPage,
  goToAccountTypes,
  goToSicCodesPage,
  goToSicSiloCodesPage,
  goToSubSiloCodesPage,
  goToSicCodeMatersPage,
  goToUsersPage,
  goToPaymentSearchPage,
  goToPaymentSummaryPage,
  goToAccountingReportsPage,
  goToLetterAndFormReportsPage,
  goToNotificationFileReportsPage,
  goToStatusReportsPage,
  goToLeaseMasterAccountsPage,
  goToLeaseMasterPaymentPostingPage,
  goToLeaseMasterPaymentReconciliationPage,
  goToPaymentTotalsPage,
  goToDelinquentSummaryPage,
  goToPaymentReturnedPaymentPage,
  goToRolesPage,
  goToPaymentDelinquentReturnedPaymentPage,
  goToDelinquentAdjustmentPage,
} from '../../utils/routeActions'
import MenuItemDrawer from './RenderDrawerList'

const drawerWidth = 240

interface Props {
  open: boolean
  alwaysTemporary?: boolean
  onClose(): void
}

export default function NavigationDrawer(props: Props): JSX.Element {
  const { t } = useTranslation()
  const navigate = useNavigate()
  const location = useLocation()
  const isTablet = useMediaQuery('(max-width:1199px)')
  const variant = props.alwaysTemporary ? 'temporary' : isTablet ? 'temporary' : 'permanent'

  const itemList = [
    {
      text: t('dashboard'),
      icon: <DashboardIcon />,
      onClick: (): void => {
        goToHomePage(navigate)
        props.onClose()
      },
      selected: location.pathname === '/',
    },
    {
      text: t('basicCost'),
      icon: <MonetizationOnIcon />,
      onClick: (): void => {
        goToBasicCostPage(navigate)
        props.onClose()
      },
      selected: location.pathname === paths.basicCost,
    },
    {
      text: t('accounts'),
      icon: <StorefrontIcon />,
      onClick: (): void => {
        goToAccounts(navigate)
        props.onClose()
      },
      selected: Boolean(location.pathname.match(startsWithAccountsRegex)),
    },
    {
      text: t('leaseMaster'),
      icon: <StoreIcon />,
      selected: Boolean(location.pathname.match(/^\/lease-master/i)),
      hidden: isInProduction,
      items: [
        {
          text: t('accounts'),
          onClick: (): void => {
            goToLeaseMasterAccountsPage(navigate)
            props.onClose()
          },
          selected: Boolean(location.pathname.match(startsWithLeaseMasterAccountsRegex)),
        },
        {
          text: t('paymentPosting'),
          onClick: (): void => {
            goToLeaseMasterPaymentPostingPage(navigate)
            props.onClose()
          },
          selected: location.pathname === paths.leaseMasterPaymentPostingPage,
        },
        {
          text: t('paymentReconciliation'),
          onClick: (): void => {
            goToLeaseMasterPaymentReconciliationPage(navigate)
            props.onClose()
          },
          selected: location.pathname === paths.leaseMasterPaymentReconciliationPage,
        },
      ],
    },
    {
      text: t('payments'),
      icon: <PaymentIcon />,
      selected: Boolean(location.pathname.match(startsWithPaymentRegex)),
      hidden: isInProduction,
      items: [
        {
          text: t('paymentSearch'),
          icon: <FindInPageIcon />,
          onClick: (): void => {
            goToPaymentSearchPage(navigate)
            props.onClose()
          },
          selected: location.pathname === paths.paymentSearch,
        },
        {
          text: t('paymentSummary'),
          icon: <HistoryEduIcon />,
          selected: Boolean(location.pathname.match(startsWithPaymentRegex)),
          items: [
            {
              text: t('currentSummary'),
              onClick: (): void => {
                goToPaymentSummaryPage(navigate)
                props.onClose()
              },
              selected: location.pathname === paths.paymentSummary,
            },
            {
              text: t('delinquentSummary'),
              onClick: (): void => {
                goToDelinquentSummaryPage(navigate)
                props.onClose()
              },
              selected: location.pathname === paths.delinquentSummary,
            },
          ],
        },
        {
          text: t('paymentTotals'),
          icon: <AttachMoneyIcon />,
          onClick: (): void => {
            goToPaymentTotalsPage(navigate)
            props.onClose()
          },
          selected: location.pathname === paths.paymentTotals,
        },
        {
          text: t('paymentReturned'),
          icon: <HistoryEduIcon />,
          selected: Boolean(location.pathname.match(startsWithPaymentRegex)),
          items: [
            {
              text: t('currentYearReturnedPayment'),
              onClick: (): void => {
                goToPaymentReturnedPaymentPage(navigate)
                props.onClose()
              },
              selected: location.pathname === paths.paymentReturnedPayment,
            },
            {
              text: t('delinquentYearReturnedPayment'),
              onClick: (): void => {
                goToPaymentDelinquentReturnedPaymentPage(navigate)
                props.onClose()
              },
              selected: location.pathname === paths.paymentDelinquentYear,
            },
          ],
        },
      ],
    },
    {
      text: t('delinquency'),
      icon: <RunningWithErrorsIcon />,
      selected: Boolean(location.pathname.match(startsWithDelinquencyRegex)),
      hidden: isInProduction,
      items: [
        {
          text: t('delinquentAdjustment'),
          onClick: (): void => {
            goToDelinquentAdjustmentPage(navigate)
            props.onClose()
          },
          selected: location.pathname === paths.delinquentAdjustment,
        },
      ],
    },
    {
      text: t('reportModule'),
      icon: <DescriptionIcon />,
      selected: Boolean(location.pathname.match(/report-module/)),
      hidden: isInProduction,
      items: [
        {
          text: t('statusReport'),
          onClick: (): void => {
            goToStatusReportsPage(navigate)
            props.onClose()
          },
          selected: location.pathname === paths.statusReport,
        },
        {
          text: t('accountingReports'),
          onClick: (): void => {
            goToAccountingReportsPage(navigate)
            props.onClose()
          },
          selected: location.pathname === paths.accountingReports,
        },
        {
          text: t('letterAndForms'),
          onClick: (): void => {
            goToLetterAndFormReportsPage(navigate)
            props.onClose()
          },
          selected: location.pathname === paths.letterAndFormReports,
        },
        {
          text: t('notificationFiles'),
          onClick: (): void => {
            goToNotificationFileReportsPage(navigate)
            props.onClose()
          },
          selected: location.pathname === paths.notificationFileReports,
        },
      ],
    },
    {
      text: t('accountType'),
      icon: <StorefrontIcon />,
      onClick: (): void => {
        goToAccountTypes(navigate)
        props.onClose()
      },
      selected: location.pathname === paths.accountType,
    },
    {
      text: t('propertyClassification'),
      icon: <SettingsApplicationsIcon />,
      onClick: (): void => {
        goToPropertyClassificationPage(navigate)
        props.onClose()
      },
      selected: location.pathname === paths.propertyClassification,
    },
    {
      text: t('sicCodeMaster'),
      icon: <SettingsApplicationsIcon />,
      onClick: (): void => {
        goToSicCodeMatersPage(navigate)
        props.onClose()
      },
      selected: location.pathname === paths.sicCodeMasters,
    },
    {
      text: t('sicCode'),
      icon: <SettingsApplicationsIcon />,
      onClick: (): void => {
        goToSicCodesPage(navigate)
        props.onClose()
      },
      selected: location.pathname === paths.sicCodes,
    },
    {
      text: t('sicSiloCode'),
      icon: <SettingsApplicationsIcon />,
      onClick: (): void => {
        goToSicSiloCodesPage(navigate)
        props.onClose()
      },
      selected: location.pathname === paths.sicSiloCodes,
    },
    {
      text: t('subSiloCode'),
      icon: <SettingsApplicationsIcon />,
      onClick: (): void => {
        goToSubSiloCodesPage(navigate)
        props.onClose()
      },
      selected: location.pathname === paths.subSiloCodes,
    },
    {
      text: t('appraiserRegions'),
      icon: <EditLocationAltIcon />,
      onClick: (): void => {
        goToAppraiserRegionsPage(navigate)
        props.onClose()
      },
      selected: location.pathname === paths.appraiserRegions,
    },
    {
      text: t('adminUsers'),
      icon: <PersonIcon />,
      onClick: () => goToUsersPage(navigate),
      selected: location.pathname === paths.adminUsers,
    },
    {
      text: t('roles'),
      icon: <SecurityIcon />,
      onClick: () => goToRolesPage(navigate),
      selected: location.pathname === paths.roles,
    },
  ]

  const drawer = (
    <Box sx={{ overflow: 'auto' }}>
      <MenuItemDrawer menuItems={itemList} />

      <Divider />
    </Box>
  )

  return (
    <Drawer
      variant={variant}
      open={props.open}
      onClose={props.onClose}
      ModalProps={{
        keepMounted: true,
      }}
      sx={{
        width: drawerWidth,
        ['& .MuiDrawer-paper']: { boxSizing: 'border-box', width: drawerWidth },
      }}
    >
      <Toolbar />
      {drawer}
    </Drawer>
  )
}

const startsWithAccountsRegex = new RegExp(`^${paths.accounts}`, 'i')
const startsWithPaymentRegex = new RegExp(`^${paths.payments}`, 'i')
const startsWithDelinquencyRegex = new RegExp(`^${paths.delinquency}`, 'i')
const startsWithLeaseMasterAccountsRegex = new RegExp(`^${paths.leaseMasterAccountsPage}`, 'i')
